import axios from "axios";
import  counties  from "../../components/helpfulresources/staticCountiesList";
//fetching counties from the data base.

const countiesFetching = () => async (dispatch) => {
  dispatch({ type: "countylist" });
  try {
    // const response = await axios.post(
    //   "https://stage.texasfostercare.org:4201/getCounties",
    //   // "http://192.168.1.111:5000/getCounties",
    //   {
    //     keyword: btoa("countyList|" + Math.random(0, 5) * 10),
    //   },
    //   {
    //     headers: {
    //       "Content-Type": "text/json",
    //     },
    //   }
    // );
    const clist=counties();
    // dispatch({ type: "countylist1", payload: response.data });
    dispatch({ type: "countylist1", payload: clist });
  } catch (error) {
    dispatch({ type: "error", payload: "error" });
  }
};


//fetching the agencies.

const agenciesFetching = () => async (dispatch,getState) => {
  dispatch({ type: "agencylist" });
  const state = getState();
  const CountyName = state.countyList.countyName;
  // console.log(CountyName);
  dispatch({ type: "countySelected", selectedCounty: CountyName });
  try {
    const response = await axios.post(
      // "http://stage.texasfostercare.org:4204/searchAgency",
      "https://stage.texasfostercare.org:4201/searchAgency",
      // "http://192.168.1.111:5000/searchAgency",
      {
        keyword: btoa("keyStroke|" + CountyName + "|" + Math.random(0, 5) * 10),
      },
      {
        headers: {
          "Content-Type": "text/json",
        },
      }
    );
    const agencies = response.data;  
    console.log(agencies)
    dispatch({ type: "fetchAgencies", payload: agencies, });
  } catch (error) {
    console.error("An error occurred during form submission:", error);
  }
};

export { countiesFetching, agenciesFetching };
